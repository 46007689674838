import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBoxWithoutLoading';
import gpuHubInvoiceApi from '@/api/gpuHub/gpuHubInvoice';
import { invoiceStatusText } from "@/constant/vmMachineStatusConst";
import { colorGpuNodeColor } from "@/constant/vmMachineStatusConst";
import { debounce } from 'vue-debounce';
import { getlazyLoadInfo } from "@/scripts/_helpers/userHelper";
import moment from "moment";
import { mapState } from "vuex";
import mappingTicketType from "@/constant/mappingTicketType"; 
export default {
    extends: baseComponent,
    props: {
        cardHeight: {
            type: String,
            default: 'height: calc(100dvh - 7.2rem);'
        },
        minInfo: {
            type: Boolean,
            default: false
        },
        ownedTarget: {
            type: String,
            default: null
        },
    },
    components: {
        paidTimes: lazyLoadComponent({
            componentFactory: () => import("@/views/components/common/PaidTimes"),
            loading: SkeletonBox,
        }),
        reportSuportTicketModalContent: lazyLoadComponent({
            componentFactory: () => import("@/views/modal/RentalService/ReportSupportTicket"),
            loading: SkeletonBox,
        }),
    },
    data() {
        return {
            debounceFnc: null,
            debounceFilterFnc: null,
            colorGpuNodeColor: colorGpuNodeColor,
            elements: {
                queryAction: "",
                querySearch: "",
                filterItem: {
                    statusFilter : ""
                },
                data: [],
                pagingItem: {
                    pageIndex: 1,
                    pageSize: 20,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "created_at",
                    directionSort: "desc"
                }
            },
            rentalPackage: [],
            toogleFilter: false,
            elementDetail: null,
            reportTicketModal: {
                isShowModal: false,
                machineInfo: null,
                postModelData: null
            }
        };
    },
    filters: {
    },
    computed: {
        ...mapState({
            userInfo: state => state.auth.user,
            rolesInfo: state => state.auth.roles,
            staffsInfo: state => state.staffs,
            listStaff: state => state.staffs,
            $emailAllowedChangeSource: (state) => state.emailAllowedChangeSource,
        }),
        defaultPagingItem() {
            return {
                pageIndex: 1,
                pageSize: 20,
                numberOfPage: 1,
                outRowsNumber: 0,
                orderBy: "created_at",
                directionSort: "desc"
            };
        },
        requestParam() {
            return {
                ownedTarget: this.ownedTarget,
                queryAction: this.elements.queryAction,
                querySearch: this.elements.querySearch,
                pageSize: this.elements.pagingItem.pageSize,
                filterItem: this.elements.filterItem
            };
        },
        invoiceStatusFilter() {
            return Object.keys(invoiceStatusText).map(x => {
                return { id: x, text: invoiceStatusText[x] };
            });
        },
    },
    created() {
        this.getAllResult(1);
        this.debounceFnc = debounce(() => {
            this.getSearchQueryResult(1);
        }, 1000);
        this.debounceFilterFnc = debounce(() => {
            this.getFilterQueryResult(1);
        }, 1000);
    },
    methods: {
        getElementsList(pageNumber) {
            this.showLoading();
            gpuHubInvoiceApi.getElementsList(pageNumber, this.requestParam).then(response => {
                if (response.data && response.data.result === 0 && response.data.data !== null) {
                    this.elements.data = response.data.data.data.map(x => {
                        x.preventVeryfy = false;
                        return x;
                    });
                    this.elements.pagingItem = response.data.data.pagingItem;
                    this.hideLoading();
                }
                else {
                    this.elements.data = [];
                    this.elements.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    if (response.data && response.data.message !== null && response.data.message !== '') {
                        commonAct.showError(response.data.message || this.$lang.common.error);
                    }
                }
            }).catch(error => {
                console.error(error);
                this.elements.data = [];
                this.elements.pagingItem = this.defaultPagingItem;
                this.hideLoading();
                commonAct.showError(error);

            });
        },
        getAllResult(pageNumber) {
            this.elements.queryAction = "";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getSearchQueryResult(pageNumber) {
            this.elements.queryAction = "search";
            this.elements.filterItem = {};
            this.getElementsList(pageNumber);
        },
        getFilterQueryResult(pageNumber) {
            this.elements.queryAction = "filter";
            this.getElementsList(pageNumber);
        },
        pageClickHandle(pageNumber) {
            switch (this.elements.queryAction) {
                case "filter":
                    this.getFilterQueryResult(pageNumber);
                    break;
                case "search":
                    this.getSearchQueryResult(pageNumber);
                    break;
                default:
                    this.getAllResult(pageNumber);
                    break;
            }
        },
        refreshClick() {
            this.elements.querySearch = "";
            this.getAllResult(1);
        },
        onSearchInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFnc) this.debounceFnc();
            } else {
                this.getSearchQueryResult(1);
            }
        },
        onFilterInput(isDebounce) {
            if (isDebounce) {
                if (this.debounceFilterFnc) this.debounceFilterFnc();
            } else {
                this.getFilterQueryResult(1);
            }
        },
        viewDetailInfo(targetItem) {
            this.$set(this, "elementDetail", targetItem);
            this.$nextTick(() => {
                $("#detailModalInfo").modal({ backdrop: 'static', keyboard: true, });
            });
        },
        closeDetailInfo() {
            $("#detailModalInfo").modal("hide");
            this.$set(this, "elementDetail", null);
        },
        onFilterToday() {
            let startDate = moment().startOf('day').format('DD/MM/YYYY');
            let endDate = moment().startOf('day').add(1, 'days').format('DD/MM/YYYY');
            this.elements.filterItem.createdFilter = [startDate, endDate];
            this.getFilterQueryResult(1);
        },
        trackInvoice(invoiceId) {
            commonAct.showConfirm(
                `Track this payment ?`,
                () => {
                    gpuHubInvoiceApi.trackInvoice(invoiceId).then(response => {
                        if (response.data && response.data.result === 0) {
                            this.showSuccessToast(
                                "Perform action has been success"
                            );
                        }
                        else {
                            if (response.data && response.data.message !== null && response.data.message !== '') {
                                commonAct.showError(response.data.message || this.$lang.common.error);
                            }
                        }
                    }).catch(error => {
                        console.error(error);
                        commonAct.showError(error);
                    });
                });
        },
        verifyPayment(element) {
            commonAct.showConfirm(
                `Verify this payment ?`,
                () => {
                    element.preventVeryfy = true;
                    gpuHubInvoiceApi.verifyPayment(element.id).then(response => {
                        if (response.data && response.data.result === 0) {
                            let vpc_TxnResponseCode = null;
                            let relatedResource = null;
                            console.log('paymentInstrumentType', element.paymentInstrumentType.toLowerCase());
                            switch (element.paymentInstrumentType.toLowerCase()) {
                                case "paypal":
                                    relatedResource = response.data.data.transactions[0].relatedResources[0];
                                    if (relatedResource) {
                                        let saleState = relatedResource.sale.state;
                                        if (saleState === 'approved' || saleState === 'completed') {
                                            this.showSuccessToast(
                                                `Payment status <strong class='text-capitalize'>${saleState}</strong>`
                                            );
                                        }
                                        else {
                                            this.showDangerToast(
                                                `Payment status <strong class='text-capitalize'>${saleState}</strong>`
                                            );
                                        }
                                    }
                                    else {
                                        this.showDangerToast(
                                            `Not found sale data.`
                                        );
                                    }
                                    break;
                                case "onepay":
                                    vpc_TxnResponseCode = response.data.data.vpc_TxnResponseCode;
                                    if (vpc_TxnResponseCode === "0") {
                                        this.showSuccessToast(response.data.message);
                                    }
                                    else {
                                        this.showDangerToast(response.data.message);
                                    }
                                    break;
                                case "momo":
                                    var momoStatus = response.data.data.data.status;
                                    if (momoStatus !== null && momoStatus === 0) {
                                        this.showSuccessToast(response.data.message);
                                    }
                                    else {
                                        this.showDangerToast(response.data.message);
                                    }
                                    break;
                            }
                            
                        }
                        else {
                            if (response.data && response.data.message !== null && response.data.message !== '') {
                                commonAct.showError(response.data.message || this.$lang.common.error);
                            }
                        }
                        element.preventVeryfy = false;
                    }).catch(error => {
                        console.error(error);
                        commonAct.showError(error);
                        element.preventVeryfy = false;
                    });
                });
        },
        onWaypoint({ element }) {
            if (!element.hasOwnProperty("isLazyloadData") || !element.isLazyloadData) {
                getlazyLoadInfo(this, element.userInfo, element.userId);
                this.$set(element, 'isLazyloadData', true);
                console.log("onWaypoint");
            }
        },

        openSupportTicketModal(targetItem) {
            console.log('targetItem', targetItem);
            let defaultCategory = mappingTicketType["PAYMENT"];
            this.$set(this.reportTicketModal, "targetItem", targetItem);
            this.$set(this.reportTicketModal, "userInfo", targetItem.userInfo);
            this.$set(this.reportTicketModal, "machineInfo", null);
            this.$set(this.reportTicketModal, "postModelData", {
                subject: `${targetItem.userInfo.username}`,
                mess: "",
                category_id: defaultCategory,
                type_id: null,
                machineId: null,
                username: `${targetItem.userInfo.username}`,
                name: this.userInfo.fullName,
                targetItemIdentity: targetItem.paymentId
            }); 
            this.$nextTick(() => {
                this.$set(this.reportTicketModal, "isShowModal", true);
            });
        },
        closeSupportTicketModal() {
            this.$set(this.reportTicketModal, "isShowModal", false);
            this.$set(this.reportTicketModal, "userInfo", null);
            this.$set(this.reportTicketModal, "machineInfo", null);
            this.$set(this.reportTicketModal, "postModelData", null); 
        },
    }
}